import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic03 from '../assets/images/pic03.jpg'
import pic08 from '../assets/images/pic08.jpg'
import pic10 from '../assets/images/pic10.jpg'

const Elements = (props) => (
    <Layout>
        <Helmet>
            <title>Cryptotech.guru - Conferences and Education</title>
            <meta name="description" content="Cryptotech.guru - Conferences and Education" />
        </Helmet>

        <div id="main" className="alt">

            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Conferences and Education</h1>
                    </header>

                    <h2 id="content">Sharing our Passion for Decentralized consensus</h2>
                    <p>Our consultants regularly appear at global conferences and media outlets. We also work at the grassroot level.</p>
                    <div className="grid-wrapper">
                        <div className="col-8">
                            <h3>University Appearances</h3>
                            <ul>
                              <li>Columbia University (2016) - Real estate applications of blockchain technology</li>
                              <li>University of Massachusetts (2016) - Blockchain in agriculture & food supply</li>
                              <li>Massachusett Institute of Technology (2017) - Decentralized consensus applications</li>
                              <li>University of California - Davis (2017) - Incentivizing a sustainable food cycle</li>
                              <li>Université de Laval - Québec (2018) - Blockchains and supply chain traceability</li>
                            </ul>
                        </div>
                        <div className="col-4"><p></p><span className="image fit"><img src={pic03} alt=""/></span></div>
                        <div className="col-4">
                            <h3>Global Conferences</h3>
                            <p>We excel at presenting our own projects and our client's visions at conferences, large and small.
                            Bitcoin & Blockchain, IOT, Open Source Software, Industry Conferences including Healthcare, Ag-Tech, FinTech.
                            </p>
                        </div>
                        <div className="col-4">
                            <h3>Private Education</h3>
                            <p>We work with your organization and/or development team to teach the foundations and deep understanding of blockchain and peer-to-peer consensus technologies.</p>
                        </div>
                        <div className="col-4">
                            <h3>Public Events</h3>
                            <p>We present at public events, have appeared in print and video media, and global broadcast networks. We also consulted with government committees on the value of P2P networks in supply chains.</p>
                        </div>
                        <div className="col-4"><span className="image fit"><img src={pic08} alt="" /></span></div>
                        <div className="col-4">
                            <h3>Community Education</h3>
                            <p>We organize and love to be invited to MeetUps. Blockchain is a community technology. It touches not only economics but social sciences, accelerating technology, and so much more. </p>
                        </div>
                        <div className="col-4"><span className="image fit"><img src={pic10} alt="" /></span></div>
                    </div>
                </div>
            </section>
        </div>

    </Layout>
)

export default Elements
